<template>
  <div class="main-section-input">
    <div class="main-container">
      <div class="left-side">
        <div class="image-input-container" ref="imageInput">
          <picture-input-field
            v-if="!editImageMode || changingImage"
            :allowEdits="false"
            @error="showError"
            @newfile="emitImageChange"
            @remove="
              () => {
                changingImage = false;
                emitImageChange('');
              }
            "
            @customURL="emitCustomUrl"></picture-input-field>
          <img
            v-if="editImageMode && !changingImage"
            :src="imageSrc"
            alt=""
            class="edit-image" />
          <DeleteIcon
            v-if="editImageMode && !changingImage"
            @click="
              changingImage = true;
              $emit('remove');
            "
            class="edit-image-icon"></DeleteIcon>
        </div>
        <div class="under-input-box">
          <p id="picturehelptext" class="small-text">
            A picture is worth a thousand words
          </p>
        </div>
      </div>
      <div class="middlebar">
        <div class="line"></div>
        <p class="and-or">and/or</p>
        <div class="line"></div>
      </div>
      <div class="right-side">
        <textarea
          maxlength="1000"
          @input="(e) => emitWordsChange(e.target.value)"
          v-model="content"
          type="text"
          class="big-text-input"
          placeholder="write some stuff" />
        <div class="under-input-box">
          <p id="wordhelptext" class="small-text">
            Writing sometimes is better than nothing
          </p>
        </div>
      </div>
    </div>
    <error-message ref="error"></error-message>
  </div>
</template>

<script>
import PictureInputField from "../PictureInputField.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import ErrorMessage from "../basic/ErrorMessage.vue";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  name: "PostSubmitSection",
  components: {
    PictureInputField,
    DeleteIcon,
    ErrorMessage,
  },
  mixins: [imageMixin],
  props: {
    editImageMode: {
      type: Boolean,
      default: false,
    },
    previousImage: String,
    previousText: String,
    previousExternalLink: String,
  },
  data() {
    return {
      content: "",
      changingImage: false,
      imageSrc: "",
    };
  },
  mounted() {
    if (this.previousText) this.content = this.previousText;
    if (
      this.editImageMode &&
      !this.previousImage &&
      !this.previousExternalLink
    ) {
      this.changingImage = true;
    }
    if (this.previousImage) {
      this.imageSrc = this.previousImage;
    }
    if (this.previousExternalLink) {
      this.getSrc(this.previousExternalLink).then((url) => {
        this.imageSrc = url;
      });
    }
  },
  methods: {
    emitImageChange(item) {
      this.$refs.error.clearError();
      this.$emit("newimage", item);
    },
    emitWordsChange(item) {
      this.$refs.error.clearError();
      this.$emit("newwords", item);
    },
    emitCustomUrl(item) {
      this.$refs.error.clearError();
      this.$emit("newlink", item);
    },
    showError(error) {
      this.$refs.error.addError(error);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-section-input {
  background-color: $grey-500;
  padding: $navbar-padding;
  display: flex;
  flex-direction: column;
  margin: $item-margin;
  border-radius: $border-radius;
}
.left-side {
  flex-grow: 1;
  width: 45%;
}
.middlebar {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  color: $white;
  font-size: 1rem;
  font-weight: 300;
  height: inherit;
  & > * {
    margin: 0;
    padding: 0;
  }
}
.line {
  width: 1px;
  height: 5rem;
  background-color: $white;
}
.right-side {
  flex-grow: 1;
  width: 45%;
}
.image-input-container {
  position: relative;

  background-color: $white;
  border-radius: $border-radius;
  width: 100%;
  height: $content-width-lg/3.91; //16/9 aspect ratio vs the width/2.2
  aspect-ratio: 16/9;
  overflow: hidden;
}
.main-container {
  display: flex;
}
.edit-image {
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}
.edit-image-icon {
  position: absolute;
  bottom: 0.2rem;
  right: 0.1rem;
  z-index: 1;
  color: $danger-300;
  font-size: 2rem;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    color: $danger-300;
  }
}
.big-text-input {
  width: 100%;
  aspect-ratio: 16/9;
  background-color: $white;
  height: $content-width-lg/3.91; //16/9 aspect ratio vs the width/2.2
  border-radius: $border-radius;
  &:hover {
    &::placeholder {
      color: $primary-500;
    }
  }
  &::placeholder {
    text-align: center;
    line-height: 19vw;
    font-size: 2rem;
    font-weight: 300;
    color: $grey-600;
    opacity: 1;
    transition: 0.2s;
  }
  resize: none;
}
.under-input-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: $white;
  font-weight: 200;
  font-size: 0.8rem;
  max-height: 2rem;
  justify-content: center;
  align-items: space-evenly;
}

@media screen and (max-width: $switch-width) {
  .line {
    width: 30vw;
    height: 1px;
  }
  .middlebar {
    height: 3rem;
    flex-direction: row;
    column-gap: 1rem;
  }
  .main-container {
    flex-direction: column;
  }
  .main-section-input {
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
    padding: 1rem 0.2rem;
  }
  .left-side,
  .right-side {
    width: 100%;
    max-width: 90vw;
    height: 50.625vw;
    max-height: 50.625vw;
  }
  .big-text-input,
  .image-input-container {
    height: 50.625vw;
  }
  .small-text {
    display: none;
  }
  .big-text-input::placeholder {
    line-height: 50.625vw;
  }
}
</style>
