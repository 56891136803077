<template>
  <div class="main-section">
    <div class="main-section-top">
      <div class="main-section-left">
        <input
          type="text"
          class="title-input input"
          id="title-input"
          v-model="title"
          maxlength="60"
          placeholder="give your thing an interesting title"
          @input="
            (ev) => {
              emitTitle(ev);
            }
          " />
        <error-message class="small-error" ref="titleError"></error-message>
      </div>
      <div class="spacer-small"></div>
      <div class="main-section-right">
        <input
          type="text"
          class="tag-input input"
          v-model="tags"
          id="tag-input"
          placeholder="and type in some tags (separated with a space)"
          @input="
            (ev) => {
              emitTags(ev);
            }
          " />
        <error-message class="small-error" ref="tagsError"></error-message>
      </div>
    </div>
    <textarea
      maxlength="1000"
      type="textarea"
      class="description-input input"
      v-model="description"
      id="description-input"
      placeholder="and add a brief description"
      @input="
        (ev) => {
          emitDescription(ev);
        }
      " />
    <error-message class="big-error" ref="descriptionError"></error-message>
  </div>
</template>

<script>
import ErrorMessage from "../basic/ErrorMessage.vue";
export default {
  components: { ErrorMessage },
  data() {
    return {
      tags: "",
      title: "",
      description: "",
    };
  },
  props: {
    inputTitle: String,
    inputDescription: String,
    inputTags: String,
  },
  mounted() {
    if (this.inputTitle) this.title = this.inputTitle;
    if (this.inputDescription) this.description = this.inputDescription;
    if (this.inputTags) this.tags = this.inputTags;
  },
  methods: {
    emitTitle(ev) {
      this.$refs.titleError.clearError();
      this.$emit("titleChange", ev.target.value);
    },
    emitTags(ev) {
      this.$refs.tagsError.clearError();
      this.$emit("tagsChange", ev.target.value);
    },
    emitDescription(ev) {
      this.$refs.descriptionError.clearError();
      this.$emit("descriptionChange", ev.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  background-color: $grey-500;
  padding: $navbar-padding;
  display: flex;
  flex-direction: column;
  margin: $item-margin;
  border-radius: $border-radius;
}
.main-section-top {
  display: flex;
}
.main-section-left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 48%;
}
.main-section-right {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 48%;
}
.small-error {
  max-width: 100%;
}
.spacer-small {
  flex-grow: 0.2;
}
.input {
  background-color: $white;
  border-radius: $border-radius;
  height: 2.2rem;
  width: 100%;
  font-size: 1rem;
  padding-left: $item-margin;
  color: $black;
  &:hover {
    &::placeholder {
      color: $primary-500;
    }
  }
  &::placeholder {
    font-weight: 300;
    transition: 0.2s;
  }
  resize: none;
}
#description-input {
  height: 6rem;
}
@media screen and (max-width: $switch-width) {
  .main-section {
    flex-direction: column;
    row-gap: 0.5rem;
    padding: 0.5rem;
  }
  .main-section-left,
  .main-section-right {
    max-width: none;
  }
  .main-section-top {
    flex-direction: column;
    row-gap: 0.5rem;
  }
}
</style>
