<template>
  <div>
    <Transition name="popout">
      <div
        class="error-messages"
        v-if="shownErrors.length"
        :class="shaking ? 'shaking' : ''"
        @animationend="shaking = false">
        <div class="error-text" v-for="error in shownErrors" :key="error">
          {{ error }}
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  data() {
    return {
      shaking: false,
      shownErrors: [],
    };
  },
  methods: {
    shake() {
      this.shaking = true;
    },
    clearError() {
      this.shownErrors.splice(0);
    },
    addError(string) {
      this.$el.parentNode.scrollIntoView();
      if (this.shownErrors.includes(string)) {
        this.shake();
      } else {
        this.shownErrors.push(string);
      }
    },
    addErrors(array) {
      if (!(array instanceof Array)) {
        this.addError(array);
        return;
      }
      array.forEach((string) => {
        if (this.shownErrors.includes(string)) {
          this.shake();
        } else {
          this.shownErrors.push(string);
        }
      });

      this.$el.parentNode.scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-messages {
  max-width: 100%;
  border: solid $danger-500 1px;
  border-top: none;
  background-color: $white;
  padding: $item-margin;
  border-radius: 0 0 $border-radius $border-radius;
  overflow: hidden;
}
.error-text {
  color: $danger-500;
  font-weight: 300;
  overflow-wrap: break-word;
  padding-left: 0.5rem;
}
.error-text::before {
  content: "• ";
}

.shaking {
  animation: shaking 0.5s;
}
@keyframes shaking {
  0% {
    transform: translateX(-2px);
  }
  25% {
    transform: translateX(2px);
  }
  50% {
    transform: translateX(-2px);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0px);
  }
}
</style>
